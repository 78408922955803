import { useEffect, useState } from 'react';
import axios from 'axios';
import { Prize } from '../components/Winners';
import { placeToString } from '../components/WinnerUser';

export const useGetRainPrizes = () => {
    const [prizes, setPrizes] = useState<Prize[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    
    useEffect(() => {
        const fetchPrizes = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_STRAPI_URL + '/api/rain-leaderboard-page', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + process.env.REACT_APP_STRAPI_API_KEY,
                },
                params: {
                    populate: 'prizes'
                }
            });
            const prizeResponse: Prize[] = [];
            for (let i = 0; i < 10; i++) {
                const place = placeToString(i + 1);
                const prize = response.data.data.attributes.prizes[place?.toLowerCase()];
                const prizeObj: Prize = {
                    place: i + 1,
                    prize: prize
                }
                prizeResponse.push(prizeObj);
            }
            setPrizes(prizeResponse);
        } catch (err) {
            console.log(err);
            setError('An error occurred while fetching the leaderboard.');
        } finally {
            setLoading(false);
        }
        };
    
        fetchPrizes();
    }, []);
    
    return { prizes, loading, error };
}