import { useEffect, useState } from 'react';
import axios from 'axios';

export interface AffiliateCode {
    site: string;
    code: string;
    bonus: string;
    image: string;
	url: string;
	rank: number;
}

export const useGetAffiliateCodes = () => {
	const [affiliateCodes, setAffiliateCodes] = useState<AffiliateCode[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const fetchAffiliateCodes = async () => {
			try {
				const response = await axios.get(process.env.REACT_APP_STRAPI_URL + '/api/codes?populate=*', {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + process.env.REACT_APP_STRAPI_API_KEY,
					}
				});
                const affiliateCodes: AffiliateCode[] = response.data.data.map((code: any) => {
                    return {
                        site: code.attributes.site,
                        code: code.attributes.code,
                        bonus: code.attributes.bonus,
                        image: process.env.REACT_APP_STRAPI_URL + code.attributes.logo.data.attributes.url,
						url: code.attributes.url,
						rank: code.attributes.rank,
                    }
                });
				// sort by rank
				affiliateCodes.sort((a, b) => a.rank - b.rank);
                setAffiliateCodes(affiliateCodes);
			} catch (err) {
				setError('An error occurred while fetching the affiliate codes.');
			} finally {
				setLoading(false);
			}
		};

		fetchAffiliateCodes();
	}, []);

	return { affiliateCodes, loading, error };
};
