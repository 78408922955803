import { useEffect, useState } from 'react';
import axios from 'axios';
import { User } from '../components/Winners';

export const useGetRainLeaderboard = (lastWeek: boolean = false) => {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [endingDate, setEndingDate] = useState<string>('');
    
    useEffect(() => {
        const fetchLeaderboard = async () => {
        try {
            const uri = lastWeek ? '/api/rain/last-week' : '/api/rain/current';
            const response = await axios.get(process.env.REACT_APP_STRAPI_URL + uri, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + process.env.REACT_APP_STRAPI_API_KEY,
                }
            });
            console.log(response.data);
            // map the response data to the User type
            const users: User[] = response.data.leaderboard?.map((user: any) => {
                return {
                    name: user.username,
                    avatar: user.avatar,
                    wagered: user.wagered
                }
            });
            setUsers(users || []);
            setEndingDate(response.data.ending);
        } catch (err) {
            console.log(err);
            setError('An error occurred while fetching the leaderboard.');
        } finally {
            setLoading(false);
        }
        };
    
        fetchLeaderboard();

    }, [lastWeek]);
    
    return { users, loading, error, endingDate };
}