import React from 'react';
import logo from '../assets/img/logo.png';
import leaderboard from '../assets/img/leaderboard.png';
import { Link } from 'react-router-dom';

interface LeaderboardNavbarProps {
    title: string;
}

export const LeaderboardNavbar: React.FC<LeaderboardNavbarProps> = ({ title }) => {
    return (
        <div className="absolute text-white flex justify-between items-center p-8 max-w-[1500px] mx-auto top-0 left-1/2 transform -translate-x-1/2 w-full z-10">
            <div className="flex flex-1 justify-start gap-4 sm:gap-20 md:gap-20 lg:gap-40 xl:gap-48 items-center">
                <Link to="/" className="hover:scale-105 transition-all">
                    <img src={logo} alt="Black cube with a white top" height="80" width="80" className="h-12 w-12 md:h-16 md:w-16 lg:h-20 lg:w-20 xl:h-24 xl:w-24" />
                </Link>
                <Link to="/leaderboards" className="hover:scale-105 transition-all hidden md:block">
                    <img src={leaderboard} alt="Leaderboard" height="80" width="80" className="mb-2 h-8 w-8 md:h-10 md:w-10 lg:h-12 lg:w-12 xl:h-16 xl:w-16" />
                </Link>
            </div>
            <h1 className="mb-2 text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-extrabold italic absolute left-1/2 transform -translate-x-1/2">
                {title}
            </h1>
            <div className="flex-1 flex justify-end">
            </div>
        </div>
    );
};
