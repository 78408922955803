import { useEffect, useState } from "react";
import { Winners } from "../components/Winners"
import { calculateTimeLeft } from "../util/calendar";
import { Link, useParams } from "react-router-dom";
import shuffle from '../assets/img/shuffle.png';
import { useGetShufflePrizes } from "../hooks/useGetShufflePrizes";
import { useGetShuffleLeaderboard } from "../hooks/useGetShuffleLeaderboard";

export const ShuffleLeaderboard: React.FC = () => {
    const { previous } = useParams();
    const lastWeek = previous === 'previous';
    const { prizes, loading: loadingPrizes, error: errorPrizes } = useGetShufflePrizes();
    const { users, loading, error, endingDate } = useGetShuffleLeaderboard(lastWeek);
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endingDate));

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft(endingDate));
        }, 1000);

        return () => clearInterval(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endingDate]);

    if (loading || loadingPrizes) return <p>Loading...</p>;
    if (error || errorPrizes) return <p className="text-red-500 mt-40 mx-auto w-80 text-center">{error || errorPrizes}</p>;
    const totalPrizes = prizes.reduce((acc, prize) => acc + prize.prize, 0);

    return (
        <>
            <section id="clash" className="h-full pt-28 z-0">
                <section className="p-8 max-w-[1500px] mx-auto">
                    <h2 className="uppercase font-bold text-4xl lg:text-5xl text-center text-white mt-20 mb-3 italic">
                        ${totalPrizes} Weekly Wager Leaderboard
                    </h2>
                    <p className="text-center text-gray-300 text-lg mb-16">
                        {timeLeft.difference && <span>The competition ends in {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s. <Link to="/leaderboards/shuffle/previous" className="text-gray-300 hover:text-white">View previous week &rarr;</Link></span>}
                        {!timeLeft.difference && <span>The competition ended at {new Date(endingDate).toLocaleString()}. <Link to="/leaderboards/shuffle" className="text-gray-300 hover:text-white">View current week &rarr;</Link></span>}
                    </p>
                    <Winners users={users} prizes={prizes} hexColor={'#8F00FF'} />
                    <div className="flex items-center justify-center mt-20">
                        <a href="https://shuffle.com/?r=Rubix" target="_blank" rel="noreferrer" className="hover:-translate-y-1 transition-transform duration-300">
                            <img src={shuffle} alt="Shuffle sign up referral banner" className="w-full lg:w-auto lg:h-96" />
                        </a>
                    </div>
                </section>
            </section>
    </>
    )
}