import { WinnerUser } from "./WinnerUser";
import { WinnerUserSmall } from "./WinnerUserSmall";

export interface User {
    name: string;
    avatar: string;
    wagered: number;
}

export interface Prize {
    place: number;
    prize: number;
}

interface WinnersProps {
    users: User[];
    prizes: Prize[];
    hexColor: string;
}

export const Winners: React.FC<WinnersProps> = ({ users, prizes, hexColor }) => {
    // sort users by wagered amount
    users.sort((a, b) => b.wagered - a.wagered);

    if (users.length === 0) {
        return (
            <h1 className="text-2xl font-bold text-center mt-20 text-green-300 uppercase italic">No wagers yet!</h1>
        )
    }

    return (
        <>
            <div className="flex flex-col lg:flex-row lg:justify-center lg:items-center gap-5 md:gap-10 xl:gap-16 mt-10">
                {/* Conditionally render the top user at the top on mobile and in the middle on desktop */}
                <div className="order-2 lg:order-1 w-full lg:w-auto flex items-center justify-center">
                    {users[1] && <div className="lg:mb-5"><WinnerUser user={users[1]} prize={prizes[1]} hexColor={hexColor}/></div>}
                </div>
                <div className="order-1 lg:order-2 w-full lg:w-auto lg:mb-0 flex items-center justify-center">
                    {users[0] && <div className="lg:mb-10"><WinnerUser user={users[0]} prize={prizes[0]} hexColor={hexColor}/></div>}
                </div>
                <div className="order-3 w-full lg:w-auto flex items-center justify-center">
                    {users[2] && <WinnerUser user={users[2]} prize={prizes[2]} hexColor={hexColor}/>}
                </div>
            </div>

            <div className="flex flex-wrap justify-center items-center gap-5 md:gap-10 xl:gap-16 mt-5 md:mt-10 lg:mt-12">
                {users[3] && <div className="w-full lg:w-auto flex items-center justify-center"><WinnerUser user={users[3]} prize={prizes[3]} hexColor={hexColor}/></div>}
                {users[4] && <div className="w-full lg:w-auto flex items-center justify-center"><WinnerUser user={users[4]} prize={prizes[4]} hexColor={hexColor}/></div>}
            </div>

            {/* Last 5 */}
            <div className="flex flex-wrap justify-center items-center gap-5 md:gap-8 xl:gap-10 mt-5 md:mt-10 lg:mt-16">
                {users.slice(5, 10).map((user, index) => 
                    user && <div key={index} className="w-full lg:w-auto flex items-center justify-center">
                        <div className="hidden lg:block"><WinnerUserSmall user={user} prize={prizes[index + 5]} hexColor={hexColor}/></div>
                        <div className="lg:hidden"><WinnerUser user={user} prize={prizes[index + 5]} hexColor={hexColor}/></div>
                    </div>
                )}
            </div>
        </>
    )
}
