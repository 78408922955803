import landing from '../assets/img/landing.png';
import '../assets/css/Home.css';
import { DiscordOutlined, TwitchOutlined, XOutlined, YoutubeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Kick } from '../components/Kick';

export const Home = () => {
    return (
        <section className="pt-40">
            <div className="relative text-white p-8 max-w-[1500px] mx-auto">
                <div className="flex items-center justify-center flex-col gap-4">
                    <img src={landing} alt="landing" width="668"
                        className="w-100" />
                    <h2 className="font-bold text-xl max-w-2xl text-center italic mt-10">
                        The BEST gambling experience gained through
                        leaderboards, gifts, reviews & more! Get rewards on your 
                        favorite casinos & gamble responsibly.
                    </h2>
                    <div className="flex flex-col lg:flex-row items-center justify-center gap-10 mt-10">
                        <Link to="/leaderboards" className="text-5xl italic font-extrabold hover:-translate-y-1 transition-all rounded-3xl py-4 px-10 yellow__button w-96 text-center">
                            Leaderboard
                        </Link>
                        <Link to="/bonuses" className="text-5xl italic font-extrabold green__button py-4 px-10 hover:-translate-y-1 transition-all rounded-3xl w-96 text-center">
                            Bonuses
                        </Link>
                    </div>
                </div>
            </div>
            <div className="socials mt-32 py-20">
                <div className="relative text-white p-8 max-w-[1500px] mx-auto">
                    <h2 className="text-4xl italic text-left font-extrabold md:text-center">
                        Where to find me?
                    </h2>
                    <div className="grid grid-cols-1 gap-10 mt-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 justify-items-start">
                        <a href="https://discord.gg/rubix" target="_blank" rel="noreferrer"
                            className="flex items-center gap-4 hover:-translate-y-1 transition-all text-gray-300 hover:text-white">
                            <DiscordOutlined className="text-4xl lg:text-6xl" />
                            <span>
                                /rubix
                            </span>
                        </a>
                        <a href="https://x.com/GWRubix" target="_blank" rel="noreferrer"
                            className="flex items-center gap-4 hover:-translate-y-1 transition-all">
                            <XOutlined className="text-4xl lg:text-6xl" />
                            <span>
                                @GWRubix
                            </span>
                        </a>
                        <a href="https://www.twitch.tv/gwrubix" target="_blank" rel="noreferrer"
                            className="flex items-center gap-4 hover:-translate-y-1 transition-all text-gray-300 hover:text-white">
                            <TwitchOutlined className="text-4xl lg:text-6xl" />
                            <span>
                                @gwrubix
                            </span>
                        </a>
                        <a href="https://www.youtube.com/channel/UCcyUd9H8RDlonqspfzZ2kug" target="_blank" rel="noreferrer"
                            className="flex items-center gap-4 hover:-translate-y-1 transition-all text-gray-300 hover:text-white">
                            <YoutubeOutlined className="text-4xl lg:text-6xl" />
                            <span>
                                @RubixGambles
                            </span>
                        </a>
                        <a href="https://x.com/RubixRefs" target="_blank" rel="noreferrer"
                            className="flex items-center gap-4 hover:-translate-y-1 transition-all text-gray-300 hover:text-white">
                            <XOutlined className="text-4xl lg:text-6xl" />
                            <span>
                                @RubixRefs
                            </span>
                        </a>
                        <a href="https://kick.com/gwrubix" target="_blank" rel="noreferrer"
                            className="flex items-center gap-4 hover:-translate-y-1 transition-all text-gray-300 hover:text-white">
                            <div className="lg:hidden"><Kick height={'1rem'} /></div>
                            <div className="hidden lg:block"><Kick height={'2rem'} /></div>
                            <span>
                                @GWRubix
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}