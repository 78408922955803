import React from "react";
import { Outlet } from "react-router-dom"
import { LeaderboardNavbar } from "../components/LeaderboardNavbar"
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Footer } from "../components/Footer";

export const LeaderboardLayout = () => {
    const location = useLocation();
    const [title, setTitle] = React.useState('Leaderboards');

    
    useEffect(() => {
        const pathParts = location.pathname.split('/').filter(Boolean); 

        if (pathParts.length === 1 && pathParts[0] === 'leaderboards') {
            setTitle('Leaderboards');
        } else if (pathParts.length >= 2 && pathParts[0] === 'leaderboards') {
            setTitle(pathParts[1].charAt(0).toUpperCase() + pathParts[1].slice(1));
        } else {
            setTitle('Leaderboards');
        }

        document.title = title;
    }, [location, title]);

    return (
        <main className="h-full">
            <LeaderboardNavbar title={title}/>
            <Outlet />
            <Footer />
        </main>
    )
}