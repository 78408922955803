import { useEffect, useState } from 'react';
import axios from 'axios';
import { User } from '../components/Winners';

interface ShuffleLeaderboard {
    startDate: string;
    endDate: string;
}

export const useGetShuffleLeaderboard = (lastWeek: boolean = false) => {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [endingDate, setEndingDate] = useState<string>('');
    
    useEffect(() => {
        const fetchLeaderboard = async () => {
        try {
            // const uri = lastWeek ? '/api/shuffle/last-week' : '/api/shuffle/current';
            // const response = await axios.get(process.env.REACT_APP_STRAPI_URL + uri, {
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Authorization': 'Bearer ' + process.env.REACT_APP_STRAPI_API_KEY,
            //     }
            // });
            // setEndingDate(response.data.ending);

            const response = await axios.get(process.env.REACT_APP_STRAPI_URL + '/api/shuffle-leaderboards', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + process.env.REACT_APP_STRAPI_API_KEY,
                }
            });

            // find the current week
            const shuffleLeaderboards: ShuffleLeaderboard[] = response.data.data.map((leaderboard: any) => {
                return {
                    startDate: leaderboard.attributes.startDate,
                    endDate: leaderboard.attributes.endDate
                };
            });

            shuffleLeaderboards.sort((a: ShuffleLeaderboard, b: ShuffleLeaderboard) => {
                return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
            });
            
            let currentLeaderboard: ShuffleLeaderboard | undefined;
            if (lastWeek) {
                currentLeaderboard = shuffleLeaderboards.find((leaderboard: ShuffleLeaderboard) => {
                    return new Date(leaderboard.startDate) <= new Date() && new Date(leaderboard.endDate) <= new Date();
                });
            } else {
                currentLeaderboard = shuffleLeaderboards.find((leaderboard: ShuffleLeaderboard) => {
                    return new Date(leaderboard.startDate) <= new Date() && new Date(leaderboard.endDate) >= new Date();
                });
            }

            if (!currentLeaderboard) {
                setError('No current leaderboard found. Check back later.');
                return;
            }

            setEndingDate(currentLeaderboard.endDate)

            // fetch users
            const userRes = await axios.get(process.env.REACT_APP_STRAPI_URL + '/api/shuffle-wagers?populate=*', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + process.env.REACT_APP_STRAPI_API_KEY,
                }
            });

            let usersRaw = userRes.data.data;

            // filter between the start and end date
            usersRaw = usersRaw.filter((user: any) => {
                if (!currentLeaderboard) return false;
                return user.attributes.wagerDate >= currentLeaderboard.startDate && user.attributes.wagerDate <= currentLeaderboard.endDate;
            });

            // sort users by wager amount
            usersRaw.sort((a: any, b: any) => b.attributes.wagered - a.attributes.wagered);

            // map users to the frontend format
            const users = usersRaw.map((user: any) => {
                return {
                    name: user.attributes.name,
                    avatar: user.attributes.avatar,
                    wagered: user.attributes.wagered
                };
            });

            setUsers(users);
        } catch (err: any) {
            if(err.response) {
                setError(err.response.data.message);
            } else {
                setError('An error occurred while fetching the leaderboard.');
            }
        } finally {
            setLoading(false);
        }
        };
    
        fetchLeaderboard();

    }, [lastWeek]);
    
    return { users, loading, error, endingDate };
}