import { Link } from "react-router-dom"
import logo from '../assets/img/logo.png';
import { GiftFilled, MessageFilled, CrownFilled } from "@ant-design/icons";
import React from "react";
import { FiMenu, FiX } from "react-icons/fi";

interface NavbarProps {
    title: string;
}

export const Navbar: React.FC<NavbarProps> = ({ title }) => {
    const [menuOpen, setMenuOpen] = React.useState(false);
    const toggleMenu = () => setMenuOpen(!menuOpen);
    return (
        <div className="absolute text-white flex justify-between items-center p-8 max-w-[1500px] mx-auto top-0 left-1/2 transform -translate-x-1/2 w-full z-10">
            <div className="flex flex-1 justify-start gap-4 sm:gap-20 md:gap-20 lg:gap-40 xl:gap-48 items-center">
                <Link to="/" className="hover:scale-105 transition-all">
                    <img src={logo} alt="Black cube with a white top" height="80" width="80" className="h-12 w-12 md:h-16 md:w-16 lg:h-20 lg:w-20 xl:h-24 xl:w-24" />
                </Link>
            </div>
            <h1 className="text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-extrabold italic absolute left-1/2 transform -translate-x-1/2">
                {title}
            </h1>
            <div className="flex justify-center items-center gap-20">
                {title === '' && <>
                    <Link to="/bonuses" className="transition-all gap-5 items-center hover:text-yellow-500 hidden xl:flex">
                        <GiftFilled className="text-4xl"/>
                        <span className="text-2xl font-bold italic">
                            Bonuses
                        </span>
                    </Link>
                    <Link to="/leaderboards" className="transition-all gap-5 items-center hover:text-yellow-500 hidden xl:flex">
                        <CrownFilled className="text-4xl"/>
                        <span className="text-2xl font-bold italic">
                            Leaderboards
                        </span>
                    </Link>
                    <Link to="/reviews" className="transition-all gap-5 items-center hover:text-yellow-500 hidden xl:flex">
                        <MessageFilled className="text-4xl" />
                        <span className="text-2xl font-bold italic">
                            Reviews
                        </span>
                        <span className="text-gray-300 text-lg">(Coming Soon!)</span>
                    </Link>
                </>
                }
                <button className="xl:hidden z-50" onClick={toggleMenu}>
                        {menuOpen ? <FiX className="text-4xl md:text-5xl lg:text-5xl" /> : <FiMenu className="text-4xl md:text-5xl lg:text-5xl" />}
                    </button>
            </div>
            <div className={`xl:hidden fixed top-0 left-0 w-full h-screen bg-gray-900 z-20 transform ${menuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}>
				<nav className="flex flex-col items-start justify-center px-12 space-y-16 mt-20">
					<Link to="/bonuses" className="transition-all flex gap-5 items-center hover:text-yellow-500" onClick={toggleMenu}>
						<GiftFilled className="text-4xl" />
						<span className="text-2xl font-bold italic">
							Bonuses
						</span>
					</Link>
					<Link to="/leaderboards" className="transition-all flex gap-5 items-center hover:text-yellow-500" onClick={toggleMenu}>
						<CrownFilled className="text-4xl" />
						<span className="text-2xl font-bold italic">
							Leaderboards
						</span>
					</Link>
					<Link to="/reviews" className="transition-all flex gap-5 items-center hover:text-yellow-500" onClick={toggleMenu}>
						<MessageFilled className="text-4xl" />
						<span className="text-2xl font-bold italic">
							Reviews
						</span>
						<span className="text-gray-300 text-lg">(Coming Soon!)</span>
					</Link>
				</nav>
			</div>
        </div>
    )
}