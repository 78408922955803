import { Link } from 'react-router-dom';

export const Footer: React.FC = () => {
    return (
        <footer className="w-full max-w-[1500px] flex-col md:flex-row text-center md:text-left gap-8 mx-auto flex justify-between items-center p-8 py-20 bg-transparent">
            <div className="flex flex-col">
                <h3 className="text-white text-2xl italic font-extrabold">Contact</h3>
                <a href="mailto:rubix@rubixrefs.com" className="text-gray-500 hover:text-white">
                    rubix@rubixrefs.com
                </a>
                <p className="text-gray-500">Copyright &copy; 2019-2024 rubixrefs.com. All rights reserved.</p>
            </div>
            <div className="flex flex-row gap-12">
                <Link to="/privacy" className="text-gray-500 hover:text-white mr-4">
                    Privacy Policy
                </Link>
                <Link to="/terms" className="text-gray-500 hover:text-white">
                    Terms of Service
                </Link>
            </div>      
        </footer>
    )
}
