import { Link } from "react-router-dom"

export const NotFound = () => {
    return (
        <div className="relative text-white p-8 max-w-[1500px] mx-auto">
            <div className="flex flex-col items-center justify-center h-screen">
                <h1 className="text-3xl md:text-8xl text-white italic font-extrabold">Page not found</h1>
                <p className="mt-4 text-white text-sm md:text-xl">The page you are looking for does not exist</p>
                <Link to="/">
                    <button className="text-xl italic font-extrabold hover:-translate-y-1 transition-all rounded-3xl py-4 px-10 green__button mt-10 text-center">Go back to home</button>
                </Link>
            </div>
        </div>
    )
}