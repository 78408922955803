import { AffiliateCode } from "../hooks/useGetAffiliateCodes";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";
import { useState } from "react";

interface ReferralCodeProps {
    affiliateCode: AffiliateCode;
}

export const ReferralCode: React.FC<ReferralCodeProps> = ({ affiliateCode }) => {
    const [copied, setCopied] = useState(false);

    // Copy code to clipboard
    const copyCode = (event: React.MouseEvent<any>) => {
        event.stopPropagation();
        navigator.clipboard.writeText(affiliateCode.code);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }

    const openInNewTab = (url: string) => {
        if (!url) return console.error('No URL provided');
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    return (
        <div className={`flex flex-col items-center bg-background-base bg-gradient-overlay bg-blend-overlay w-80 p-8 rounded-3xl shadow-lg hover:cursor-pointer hover:-translate-y-1 transition-all`}
             key={affiliateCode.site}
             onClick={() => openInNewTab(affiliateCode.url)}>
            <img src={affiliateCode.image} 
                 alt={`${affiliateCode.site}'s logo`} 
                 className="h-40"/>
            <p className="text-xl font-bold mt-4 text-white">{affiliateCode.site}</p>
            <div className="px-12 py-2 my-4 shadow-md text-white text-3xl uppercase font-extrabold rounded-2xl italic code__button flex gap-2">
                {affiliateCode.code}
                {!copied && <CopyOutlined className="ml-2 text-lg hover:text-green-400 cursor-pointer"
                    onClick={(event) => copyCode(event)} />}
                {copied && <CheckOutlined className="text-lg text-green-400" />}
            </div>
            <h2 className="text-lg mt-3 font-bold text-green-400">
                {affiliateCode.bonus}
            </h2>
        </div>
    )
}
