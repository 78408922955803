import './App.css';
// import { ClashLeaderboard } from './pages/ClashLeaderboard';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Home } from './pages/Home';
import { LeaderboardLayout } from './layouts/LeaderboardLayout';
import { LeaderboardLanding } from './pages/LeaderboardLanding';
import { NormalLayout } from './layouts/NormalLayout';
import { Bonus } from './pages/Bonus';
import { NotFound } from './pages/NotFound';
import { ShuffleLeaderboard } from './pages/ShuffleLeaderboard';
import { RainLeaderboard } from './pages/RainLeaderboard';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<NormalLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/bonuses" element={<Bonus />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </Route>

          {/* Leaderboard */}
          <Route element={<LeaderboardLayout />}>
						<Route path="/leaderboards" element={<LeaderboardLanding />} />
						{/* <Route path="/leaderboards/clash" element={<ClashLeaderboard />} />
						<Route path="/leaderboards/clash/:previous" element={<ClashLeaderboard />} /> */}
            <Route path="/leaderboards/shuffle" element={<ShuffleLeaderboard />} />
            <Route path="/leaderboards/shuffle/:previous" element={<ShuffleLeaderboard />} />
            <Route path="/leaderboards/rain" element={<RainLeaderboard />} />
            <Route path="/leaderboards/rain/:previous" element={<RainLeaderboard />} />
          </Route>
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
