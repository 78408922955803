import { Link } from "react-router-dom"
import '../assets/css/Leaderboard.css'

export const LeaderboardLanding = () => {
    return (
        <div className="leaderboard__landing">
            <div className="relative text-white p-8 max-w-[1500px] mx-auto">
                <div className="flex flex-col items-center justify-center pt-32">
                    <h2 className="text-white italic text-xl md:text-4xl text-center max-w-4xl py-10 md:py-32">
                        Weekly wager leaderboards on various sites, e.g. Shuffle and Rain. Any wager abuse tactics will be noticed and you will be disqualified.
                    </h2>
                    <div className="flex flex-col lg:flex-row items-center justify-center gap-10 mt-10 mb-40">
                        <Link to="/leaderboards/rain" className="text-5xl italic font-extrabold hover:-translate-y-1 transition-all rounded-3xl py-4 px-10 red__button w-80 text-center">
                            Rain
                        </Link>
                        <Link to="/leaderboards/shuffle" className="text-5xl italic font-extrabold hover:-translate-y-1 transition-all rounded-3xl py-4 px-10 purple__button w-80 text-center">
                            Shuffle
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}