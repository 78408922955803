import React from "react";
import { Outlet } from "react-router-dom"
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";

export const NormalLayout = () => {
    const location = useLocation();
    const [title, setTitle] = React.useState('');

    
    useEffect(() => {
        const pathParts = location.pathname.split('/').filter(Boolean); 

        if (pathParts.length === 0) {
            setTitle('');
        } else if (pathParts[0] === '404') {
            setTitle('Not Found');
        } else if (pathParts.length > 0) {
            setTitle(pathParts[0].charAt(0).toUpperCase() + pathParts[0].slice(1));
        } else {
            setTitle('');
        }
    }, [location, title]);

    return (
        <main className="h-full">
            <Navbar title={title}/>
            <Outlet />
            <Footer />
        </main>
    )
}