import { ReferralCode } from "../components/ReferralCode";
import { useGetAffiliateCodes } from "../hooks/useGetAffiliateCodes";

export const Bonus: React.FC = () => {
    const { error, loading, affiliateCodes } = useGetAffiliateCodes();

    if (loading) return <p>Loading...</p>
    if (error) return <p>{error}</p>

    return (
        <div className="flex flex-col items-center justify-center pt-48 mb-48 h-auto">
            {/* first three */}
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-16">
                {affiliateCodes.slice(0, 3).map((affiliateCode) => (
                    <ReferralCode affiliateCode={affiliateCode} />
                ))}
            </div>

            {/* four and five */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mt-16">
                {affiliateCodes.slice(3, 5).map((affiliateCode) => (
                    <ReferralCode affiliateCode={affiliateCode} />
                ))}
            </div>

            {/* rest */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 mt-16">
                {affiliateCodes.slice(5).map((affiliateCode) => (
                    <ReferralCode affiliateCode={affiliateCode} />
                ))}
            </div>

            <p className="text-gray-300 mt-48 max-w-3xl text-center">
                All websites are verified and tested to ensure a safe gambling experience for everyone. Use the affiliate codes to support me (Rubix) and allow me to provide giveaways and other prizes to all my viewers and people in the community.
            </p>
        </div>
    )
}