export function calculateTimeLeft(endingDate: string) {
    const now = new Date().getTime();
    const end = new Date(endingDate).getTime();
    const difference = end - now;

    let timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        difference: false,
    };

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((difference % (1000 * 60)) / 1000),
            difference: true,
        };
    }

    return timeLeft;
}